const API_BASE_URL = process.env.API_HOMEPAGE_BASE_URL
const API_VERSION = process.env.API_HOMEPAGE_VERSION
const API_BASE = API_BASE_URL + '/' + API_VERSION
const AUTH_API = process.env.AUTH_API

/**
 * Make RESTFUL Request, adapt JSONAPI standard
 *
 * @param {*} path
 * @param {*} method
 * @param {*} language
 */
export async function makeAPIRequest(
	path: string,
	method = 'get',
	language = 'vi',
	expectMeta = false
) {
	const res = await fetch(path, {
		method: method,
		headers: {
			'Content-Type': 'application/vnd.api+json',
			Accept: 'application/vnd.api+json',
			'Accept-Language': language,
			'Cache-Control': 'no-cache'
		}
	})
	return await handleResponse(res, expectMeta)
}

export async function makeRequest(
	path = '',
	method = 'get',
	body: any = null,
	language = 'vi'
) {
	const res = await fetch(path, {
		method,
		body,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'Accept-Language': language,
			'Cache-Control': 'no-cache'
		}
	})

	if (res.status != 200) {
		throw new Error('Request error')
	}

	return await res.json()
}

export async function makeServerRequest(
	path: string,
	method = 'get',
	headers = null
) {
	let requestOpts: any = {
		method: method
	}

	if (headers && !process.browser) {
		requestOpts.credentials = 'include'
		requestOpts.headers = headers
	}

	const res = await fetch(path, requestOpts)

	if (res.status != 200) {
		throw new Error('Request error')
	}

	return await res.json()
}

/**
 * Make GET RESTFUL Request, adapt JSONAPI standard
 * @param {*} path
 * @param {*} language
 */
export async function getSync(path: string, language = 'vi') {
	const res = await fetch(path, {
		headers: {
			'Content-Type': 'application/vnd.api+json',
			Accept: 'application/vnd.api+json',
			'Accept-Language': language
		}
	})
	return await handleResponse(res)
}

/**
 * Make POST RESTFUL Request, adapt JSONAPI standard
 * @param {*} path
 * @param {*} data
 */
export async function postSync(path: string, data: any = []) {
	const res = await fetch(path, {
		method: 'post',
		headers: {
			'Content-Type': 'application/vnd.api+json',
			Accept: 'application/vnd.api+json'
		},
		body: JSON.stringify(data)
	})
	return await handleResponse(res)
}

/**
 * Get user info from cookie tokens
 *
 * @param {*} jsToken
 * @param {*} wsToken
 */
export async function getUserInfo(jsToken: string, wsToken: string) {
	const path = AUTH_API
	if (path === undefined || path === '') {
		return null
	}
	const res = postSync(path, {
		jsToken: jsToken,
		wsToken: wsToken
	})
	return res
}

/**
 * Get user info from cookie tokens: Called from NODEJS
 * @param {*} jsToken
 * @param {*} wsToken
 */
export async function getUserInfoServer() {
	const path = '/?action=auth'
	const res = postSync(path)
	return res
}

export async function getAPI(path: string, language = 'en') {
	const apiPath = API_BASE + path
	return getSync(apiPath, language)
}

/**
 * Handle response from request, adapt JSONAPI standard
 *
 * @param {*} response
 */
async function handleResponse(response: Response, expectMeta = false) {
	const headers = response.headers
	const contentType = headers.get('Content-Type') || ''
	const responseData = await response.json()
	if (contentType.indexOf('application/vnd.api+json') > -1) {
		return formatJsonAPIResponse(responseData, response.status, expectMeta)
	}

	return responseData.data
}

function formatJsonAPIResponse(
	responseData: any,
	statusCode: number,
	expectMeta = false
) {
	if (statusCode === 200) {
		return formatSuccessJsonAPIResponse(responseData, expectMeta)
	}

	return []
}

function formatSuccessJsonAPIResponse(responseData: any, expectMeta = false) {
	const data = responseData.data.map((item: any) => {
		return {
			id: item.id,
			...item.attributes
		}
	})

	let metaData = null
	if (expectMeta) {
		metaData = responseData.meta || metaData
	}

	return expectMeta
		? {
				meta: metaData,
				data: data
		  }
		: data
}

export const generateCsrfToken = async (url: string) => {
	const response = await fetch(url, {
		method: 'POST',
		body: new URLSearchParams('formName=frmCreateProfile')
	})
	try {
		return await response.json()
	} catch (ex) {
		console.log('error', ex)
		return await response
	}
}
