import isEqual from 'lodash.isequal'

type IJobCorner = {
	backgroundImage: string
	button_name: string
	ctaLabel: string
	description: string
	id: number
	image: string
	message: string
	url: string
}

type IJobCornerLocals = {
	currentIndex: number
	bannerList: IJobCorner[]
}

export class JobCorner {
	constructor() {}

	hasLocalStorage: boolean = !!(
		typeof window !== 'undefined' && window.localStorage
	)

	getJobCornerStorage() {
		try {
			if (!this.hasLocalStorage) return null
			const localStorageJobCorner = localStorage.getItem('jobCorner')
			const jobCorner: IJobCornerLocals = JSON.parse(
				localStorageJobCorner ?? '{}'
			)
			return jobCorner
		} catch (error) {
			return null
		}
	}

	randomizedJobCorner(bannerList: IJobCorner[]) {
		if (!this.hasLocalStorage) return bannerList[0]
		const jobCorner = this.getJobCornerStorage()

		const emptyJobCornerLocals =
			!jobCorner?.bannerList || jobCorner.bannerList.length === 0

		if (
			emptyJobCornerLocals ||
			!isEqual(jobCorner.bannerList, bannerList)
		) {
			this.setJobCornerStorage(bannerList, 0)
			return bannerList[0]
		}
		const newIndex: number =
			jobCorner.currentIndex >= jobCorner.bannerList.length - 1
				? 0
				: jobCorner.currentIndex + 1
		this.setJobCornerStorage(bannerList, newIndex)
		return bannerList[newIndex]
	}

	setJobCornerStorage(bannerList: IJobCorner[], newIndex: number) {
		if (!this.hasLocalStorage) return null
		const jobCornerLocals: IJobCornerLocals | any = {
			bannerList: bannerList,
			currentIndex: newIndex
		}
		localStorage.setItem('jobCorner', JSON.stringify(jobCornerLocals))
	}
}

const JobCornerService = new JobCorner()
export default JobCornerService
