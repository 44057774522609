import { makeAPIRequest } from '../../services/apiClient'
import JobCornerService from './JobCorner/jobCornerService'

const methodGet = 'get'
const bannerAPIBase = process.env.API_BANNER_BASE_URL
const bannerAPIVersion = process.env.API_BANNER_VERSION
const bannerAPIVersionV2 = process.env.API_BANNER_VERSION_V2
const bannerAPIBaseUrl = `${bannerAPIBase}/${bannerAPIVersion}`
const bannerAPIBaseUrlV2 = `${bannerAPIBase}/${bannerAPIVersionV2}`
export async function getHeroBanners(langCode) {
	const apiUrl = `${bannerAPIBaseUrl}/hero?${langCode}`
	let banners = []
	let rotationTime = 0
	try {
		const data = await makeAPIRequest(apiUrl, methodGet, langCode, true)

		banners = data.data.map((banner) => {
			return {
				...banner,
				bannerImage: banner.image,
				text: banner.companyQuote
			}
		})
		rotationTime = data.meta.rotateTime || rotationTime
	} catch (err) {
		// TODO Log Error To Sentry
	}

	return {
		banners: banners,
		rotationTime: rotationTime
	}
}

export async function getFeaturedCompanies(langCode) {
	const apiUrl = `${bannerAPIBaseUrl}/featured-company?${langCode}`

	let banners = []
	try {
		const data = await makeAPIRequest(apiUrl, methodGet, langCode)

		banners = data.map((banner) => {
			return {
				...banner,
				logo: banner.image,
				name: banner.companyName,
				hasNewJobs: banner.hasNewJob || false
			}
		})
	} catch (err) {
		// TODO Log Error To Sentry
	}

	return banners
}

export async function getCompanySpotlight(langCode) {
	const apiUrl = `${bannerAPIBaseUrl}/company-spotlight?${langCode}`

	let banners = []
	let rotationTime = 0
	try {
		const data = await makeAPIRequest(apiUrl, methodGet, langCode, true)
		banners = data.data.map((banner) => {
			return {
				...banner,
				title: banner.companyName,
				message: banner.companySlogan,
				description: banner.companyMessage,
				backgroundImage: banner.image,
				logo: banner.companyLogo
			}
		})
		rotationTime = data.meta.rotateTime || rotationTime
	} catch (err) {
		// TODO Log Error To Sentry
	}
	return {
		banners: banners,
		rotationTime: rotationTime
	}
}

export async function getJobCorner(langCode) {
	const apiUrl = `${bannerAPIBaseUrl}/job-corner?${langCode}`

	let banners = []
	try {
		const data = await makeAPIRequest(apiUrl, methodGet, langCode)

		banners = data
			.map((banner) => {
				return {
					...banner,
					backgroundImage: banner.image,
					description: banner.message,
					ctaLabel: banner.button_name
				}
			})
			.sort((a, b) => a.id - b.id)
	} catch (err) {
		// TODO Log Error To Sentry
	}

	// return banners.length > 0 ? banners[Math.floor(Math.random() * banners.length)] : null
	if (banners.length > 0) {
		return JobCornerService.randomizedJobCorner(banners)
	}
	return null
}

export async function getHotCategories(langCode) {
	const apiUrl = `${bannerAPIBaseUrlV2}/hot-categories?${langCode}`
	let categories = []
	try {
		const data = await makeAPIRequest(apiUrl, methodGet, langCode)

		categories = data.map((category) => {
			return {
				...category,
				categoriesName: category.category_name ?? null,
				categoriesURL: category.category_url ?? null,
				categoriesIcon: category.category_icon ?? null,
				categoriesTotal: category.total
			}
		})
	} catch (err) {
		// TODO Log Error To Sentry
	}
	return {
		categories: categories
	}
}

export async function getPopupBanner(langCode) {
	const apiUrl = `${bannerAPIBaseUrl}/popup-banner`

	let banners = []
	let rotationTime = 0
	try {
		const data = await makeAPIRequest(apiUrl, methodGet, langCode, true)
		banners = data.data.map((banner) => {
			return {
				title: banner.title,
				description: banner.quote,
				url: banner.url,
				image: banner.image,
				repeatType: banner.repeatType,
				numberOfRepeat: banner.numberOfRepeat
			}
		})
		rotationTime = data.meta.rotateTime || rotationTime
	} catch (err) {
		// TODO Log Error To Sentry
		console.log(' Error: ', err)
	}
	return {
		banners: banners,
		rotationTime: rotationTime
	}
}
